import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import HomePage from '../Page/HomePage'
import MeetingsPage from '../Page/MeetingsPage'
import MeetingPage from '../Page/MeetingPage'
import NewMeetingPage from '../Page/NewMeetingPage'
import ErrorPage from '../Page/ErrorPage'

const CommonRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/meets" element={<MeetingsPage />} />
            <Route path="/meets/:id" element={<MeetingPage />} />
            <Route path="/new-meet/:id?" element={<NewMeetingPage />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )
}

export default observer(CommonRoutes)
