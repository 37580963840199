import { useState, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { Button, Spin, Radio } from 'antd'
import {
    EditOutlined,
    EnvironmentFilled,
    ClockCircleFilled,
} from '@ant-design/icons'

import { store } from '../../../stores/RootStore'

import { getMeetsLink } from '../../../lib/links/getMeetsLink'
import { getNewMeetLink } from '../../../lib/links/getNewMeetLink'

import Wrapper from '../../Wrapper'
import ListOfParticipants from '../../ListOfParticipants'
import Status from '../../Status'

import styles from './index.module.scss'

const MeetingPage = () => {
    const { getMeet, meet, resetMeet, isUserAdminOfMeet, changeMeetStatus } =
        store
    const { id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(id && meet?.info.id !== id)

    const loadMeet = useCallback(async () => {
        try {
            await getMeet(id)

            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }, [getMeet, id])

    const handleCloseClick = useCallback(() => {
        navigate(getMeetsLink())
        resetMeet()
    }, [navigate, resetMeet])

    const handleChangeClick = useCallback(() => {
        navigate(getNewMeetLink(id))
    }, [navigate, id])

    const handleChangeStatus = useCallback(
        (e) => {
            changeMeetStatus(meet?.info.id, e.target.value)
        },
        [changeMeetStatus, meet]
    )

    useEffect(() => {
        loadMeet()

        // eslint-disable-next-line
    }, [])

    return (
        <div className={cn(styles.root)}>
            <Wrapper>
                <header className={cn(styles.header)}>
                    <div className={cn(styles.titleWrapper)}>
                        <span className={cn(styles.title)}>Встреча</span>
                    </div>

                    <Button
                        type="primary"
                        htmlType="button"
                        onClick={handleCloseClick}
                        size="middle"
                    >
                        Встречи
                    </Button>
                </header>

                {isLoading || !meet ? (
                    <div className={cn(styles.spinner)}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className={cn(styles.content)}>
                        <div className={cn(styles.main)}>
                            <div className={cn(styles.meetName)}>
                                {meet.info.name}
                            </div>

                            {isUserAdminOfMeet && (
                                <Button
                                    htmlType="button"
                                    onClick={handleChangeClick}
                                    shape="circle"
                                >
                                    <EditOutlined
                                        className={cn(styles.editIcon)}
                                    />
                                </Button>
                            )}
                        </div>

                        <div className={cn(styles.container)}>
                            <div className={cn(styles.line)}>
                                <ClockCircleFilled
                                    className={cn(styles.lineIcon)}
                                />
                                <p>{meet.info.startAtString}</p>
                            </div>

                            <div className={cn(styles.line)}>
                                <EnvironmentFilled
                                    className={cn(styles.lineIcon)}
                                />
                                <p>{meet.info.place}</p>
                            </div>
                        </div>

                        <Radio.Group
                            className={cn(styles.status)}
                            value={meet.user.status}
                            onChange={handleChangeStatus}
                            size="middle"
                        >
                            <Radio.Button value="plus">
                                <Status status="plus" />

                                <span className={cn(styles.statusText)}>
                                    Да
                                </span>
                            </Radio.Button>
                            <Radio.Button value="question">
                                <Status status="question" />

                                <span className={cn(styles.statusText)}>
                                    Возможно
                                </span>
                            </Radio.Button>
                            <Radio.Button value="minus">
                                <Status status="minus" />

                                <span className={cn(styles.statusText)}>
                                    Нет
                                </span>
                            </Radio.Button>
                        </Radio.Group>

                        <ListOfParticipants />
                    </div>
                )}
            </Wrapper>
        </div>
    )
}

export default observer(MeetingPage)
