import { types, cast, flow } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import dayjs from 'dayjs'

import { defaultState } from './defaultState'
import { UserStore } from './UserStore'
import { MeetsMeetStore } from './MeetsMeetStore'
import { MeetStore } from './MeetStore'

import { MeetsService } from '../api/MeetsService'

const RootStore = types
    .model({
        token: types.string,
        user: UserStore,
        meets: types.array(MeetsMeetStore),
        meet: types.maybe(MeetStore),
    })
    .views((self) => ({
        get isUserAdminOfMeet() {
            return self.meet?.admin.id === self.user.id
        },
    }))
    .actions((self) => ({
        setUser(user) {
            self.user = cast(user)
        },
        setToken(token) {
            self.token = token
        },
        resetMeet() {
            self.meet = undefined
        },
        getMeets: flow(function* () {
            const data = yield MeetsService.getMeets(self.token)

            const formattedMeets = data.data.meets.map(
                ({
                    info: { id, name, place, start_at },
                    myInfo: { participantStatus },
                }) => {
                    return {
                        id,
                        name,
                        place,
                        startAt: start_at,
                        status: participantStatus,
                    }
                }
            )

            self.meets = cast(formattedMeets)
        }),
        getMeet: flow(function* (id) {
            const result = yield MeetsService.getMeet(self.token, id)

            const { info, participantGroups, myInfo } = result.data

            const meet = cast({
                info: {
                    id: info.id,
                    name: info.name,
                    place: info.place,
                    startAt: info.start_at,
                },
                participants: participantGroups.reduce(
                    (acc, group) => {
                        acc[group.status] = group.participants.map(
                            (participant) => ({
                                id: participant.id,
                                firstName: participant.first_name,
                                lastName: participant.last_name,
                                userName: participant.username,
                            })
                        )
                        return acc
                    },
                    {
                        minus: [],
                        plus: [],
                        question: [],
                    }
                ),
                admin: {
                    id: info.org_web_app_user.id,
                    firstName: info.org_web_app_user.first_name,
                    lastName: info.org_web_app_user.last_name,
                    userName: info.org_web_app_user.username,
                },
                user: { status: myInfo.participantStatus },
            })

            self.meet = cast(meet)
        }),
        saveMeet: flow(function* (id, values) {
            const date = dayjs()
                .set('year', values.date.year())
                .set('month', values.date.month())
                .set('date', values.date.date())
                .set('hour', values.time.hour())
                .set('minute', values.time.minute())

            const data = {
                name: values.title,
                place: values.place,
                start_at: date.toISOString(),
                timezone: DateTime.now().zoneName,
            }

            if (id) {
                yield MeetsService.updateMeet(self.token, id, data)

                self.meet.info.name = data.name
                self.meet.info.place = data.place
                self.meet.info.startAt = data.start_at
            } else {
                yield MeetsService.createMeet(self.token, data)
            }
        }),
        changeMeetStatus: flow(function* (id, status) {
            const prevStatus = self.meet.user.status
            self.meet.user.status = status

            try {
                yield MeetsService.changeMeetStatus(self.token, id, status)

                if (prevStatus !== null) {
                    let prevStatusIndex = self.meet.participants[
                        prevStatus
                    ].findIndex(
                        (participant) => participant.id === self.user.id
                    )

                    if (prevStatusIndex !== -1) {
                        self.meet.participants[prevStatus] = cast([
                            ...self.meet.participants[prevStatus].toSpliced(
                                prevStatusIndex,
                                1
                            ),
                        ])
                    }
                }

                self.meet.participants[status] = cast([
                    { ...self.user },
                    ...self.meet.participants[status],
                ])
            } catch (e) {
                self.meet.user.status = prevStatus
            }
        }),
    }))

export const store = RootStore.create(defaultState)
