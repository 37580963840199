import { BrowserRouter } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ConfigProvider } from 'antd'
import ruLocale from 'antd/locale/ru_RU'

import { useTgInitialData } from './hooks/useTgInitialData'

import { store } from './stores/RootStore'

import CommonRoutes from './components/CommonRoutes'

const DATA = {
    borderRadius: 6,
    colorPrimary: '#9381ff',
    Button: {
        colorPrimary: '#9381ff',
    },
}

function App() {
    const { token } = store

    useTgInitialData()

    if (!token) {
        return null
    }

    return (
        <ConfigProvider
            locale={ruLocale}
            theme={{
                token: {
                    colorPrimary: DATA.colorPrimary,
                    borderRadius: DATA.borderRadius,
                },
                components: {
                    Button: {
                        colorPrimary: '#9381ff',
                    },
                },
            }}
        >
            <main>
                <BrowserRouter>
                    <CommonRoutes />
                </BrowserRouter>
            </main>
        </ConfigProvider>
    )
}

export default observer(App)
