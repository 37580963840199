import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { getMeetLink } from '../../../lib/links/getMeetLink'
import { getMeetsLink } from '../../../lib/links/getMeetsLink'

const HomePage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const tg = window.Telegram.WebApp

        let meetId

        if (tg.initDataUnsafe?.start_param) {
            meetId = tg.initDataUnsafe.start_param.split('=')[1]
        }

        if (meetId) {
            navigate(getMeetLink(meetId))
        } else {
            navigate(getMeetsLink())
        }
    }, [navigate])

    return null
}

export default observer(HomePage)
