export const defaultState = {
    token: '',
    user: {
        id: 0,
        firstName: '',
        lastName: '',
        userName: '',
    },
    meets: [],
}
