import { types } from 'mobx-state-tree'
import { DateTime } from 'luxon'

import dayjs from 'dayjs'

export const MeetInfoStore = types
    .model({
        id: types.string,
        name: types.string,
        startAt: types.string,
        place: types.string,
    })
    .views((self) => {
        return {
            get startAtDayJs() {
                const d = new Date(self.startAt)
                return dayjs(d)
            },
            get startAtString() {
                const dateLuxon = DateTime.fromISO(self.startAt)

                return dateLuxon.toLocaleString({
                    weekday: 'long',
                    month: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                })
            },
        }
    })
