import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import styles from './index.module.scss'

const Wrapper = ({ className, children }) => {
    return <div className={cn(styles.root, className)}>{children}</div>
}

export default observer(Wrapper)
