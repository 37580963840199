import { observer } from 'mobx-react-lite'

import Wrapper from '../../Wrapper'

const ErrorPage = () => {
    return (
        <Wrapper>
            <div>404</div>
        </Wrapper>
    )
}

export default observer(ErrorPage)
