import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import ListOfParticipantsGroup from './ListOfParticipantsGroup'

import { store } from '../../stores/RootStore'

import styles from './index.module.scss'

const ListOfParticipants = ({ className }) => {
    const { meet } = store

    return (
        <div className={cn(styles.root, className)}>
            <ListOfParticipantsGroup
                title={'Список участников'}
                list={meet.participants.plus}
            />

            <ListOfParticipantsGroup
                title={'Отказались'}
                list={meet.participants.minus}
            />
        </div>
    )
}

export default observer(ListOfParticipants)
