import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { ClockCircleFilled, EnvironmentFilled } from '@ant-design/icons'

import { getMeetLink } from '../../../lib/links/getMeetLink'

import Status from '../../Status'

import styles from './index.module.scss'

const MeetsMeet = ({ meet: { id, name, status, startAtString, place } }) => {
    const navigate = useNavigate()

    const handleCardClick = useCallback(
        (id) => {
            return () => {
                navigate(getMeetLink(id))
            }
        },
        [navigate]
    )

    return (
        <li className={cn(styles.root)} onClick={handleCardClick(id)}>
            <div className={cn(styles.header)}>
                <div className={cn(styles.title)}>{name}</div>

                <Status status={status} />
            </div>

            <div className={cn(styles.container)}>
                <div className={cn(styles.line)}>
                    <ClockCircleFilled className={cn(styles.lineIcon)} />

                    <p>{startAtString}</p>
                </div>

                <div className={cn(styles.line)}>
                    <EnvironmentFilled className={cn(styles.lineIcon)} />

                    <p>{place}</p>
                </div>
            </div>
        </li>
    )
}

export default observer(MeetsMeet)
