import { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { Form as FormBase, Input, Button, DatePicker, TimePicker } from 'antd'

import styles from './index.module.scss'

const Form = ({ className, onSave, values }) => {
    const [isLoading, setIsLoading] = useState(false)

    const formRef = useRef(null)

    const onFinish = async (values) => {
        setIsLoading(true)

        await onSave(values)

        setIsLoading(false)
    }

    return (
        <div className={cn(styles.root, className)}>
            <FormBase
                ref={formRef}
                disabled={isLoading}
                onFinish={onFinish}
                layout="horizontal"
            >
                <FormBase.Item
                    name="title"
                    label="Название"
                    initialValue={values ? values.title : undefined}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Input placeholder="Событие" />
                </FormBase.Item>

                <FormBase.Item
                    name="date"
                    label="Дата"
                    initialValue={values ? values.date : undefined}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        format="DD.MM.YY"
                        inputReadOnly
                    />
                </FormBase.Item>

                <FormBase.Item
                    name="time"
                    label="Время"
                    initialValue={values ? values.date : undefined}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <TimePicker format="HH:mm" inputReadOnly />
                </FormBase.Item>

                <FormBase.Item
                    name="place"
                    label="Место"
                    initialValue={values ? values.place : undefined}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Input placeholder="Басик" />
                </FormBase.Item>

                <Button
                    className={cn(styles.button)}
                    type="primary"
                    htmlType="submit"
                >
                    Сохранить
                </Button>
            </FormBase>
        </div>
    )
}

export default observer(Form)
