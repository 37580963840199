import { types } from 'mobx-state-tree'
import { MeetInfoStore } from './MeetInfoStore'
import { MeetParticipantsStore } from './MeetParticipantsStore'
import { MeetUserStore } from './MeetUserStore'
import { UserStore } from './UserStore'

export const MeetStore = types.model({
    info: MeetInfoStore,
    participants: types.model({
        plus: types.array(MeetParticipantsStore),
        minus: types.array(MeetParticipantsStore),
        question: types.array(MeetParticipantsStore),
    }),
    admin: UserStore,
    user: MeetUserStore,
})
