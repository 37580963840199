import { useEffect } from 'react'

import { store } from '../stores/RootStore'

const TOKEN =
    'user=%7B%22id%22%3A554198397%2C%22first_name%22%3A%22%D0%9C%D0%B0%D1%80%D0%B3%D0%BE%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22mmargooo%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-8197080697652865892&chat_type=sender&start_param=meet%3D7c858367-d54a-4c1d-a95d-073af75d4ddc&auth_date=1691369182&hash=53373474d8547fdf483ac52fc2664b61a7a90fa01a5e9aa38e5390bce5e6cfde'

const USER = {
    id: 554198397,
    firstName: 'Марго',
    lastName: '',
    userName: 'mmargooo',
}

export function useTgInitialData() {
    const { setToken, setUser } = store

    useEffect(() => {
        const tg = window.Telegram.WebApp
        tg.ready()

        if (!tg.initData) {
            setToken(TOKEN)
            setUser(USER)
            return
        }

        setToken(tg.initData)

        if (tg.initDataUnsafe) {
            if (tg.initDataUnsafe.user) {
                const { id, first_name, last_name, username } =
                    tg.initDataUnsafe.user

                setUser({
                    id,
                    firstName: first_name,
                    lastName: last_name,
                    userName: username,
                })
            }
        }
    }, [setToken, setUser])
}
