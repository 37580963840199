import { useState, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { Button, Spin } from 'antd'

import { store } from '../../../stores/RootStore'

import { getMeetLink } from '../../../lib/links/getMeetLink'
import { getMeetsLink } from '../../../lib/links/getMeetsLink'

import Wrapper from '../../Wrapper'
import Form from '../../Form'

import styles from './index.module.scss'

const NewMeetingPage = () => {
    const { meet, getMeet, saveMeet } = store
    const { id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(id && meet?.info.id !== id)

    const handleCloseClick = useCallback(() => {
        if (meet) {
            navigate(getMeetLink(meet.info.id))
        } else {
            navigate(getMeetsLink())
        }
    }, [navigate, meet])

    const handleSaveClick = useCallback(
        async (values) => {
            try {
                await saveMeet(meet?.info.id, values)

                if (meet) {
                    navigate(getMeetLink(meet.info.id))
                } else {
                    window.Telegram.WebApp.close()
                }
            } catch (e) {
                throw e
            }
        },
        [saveMeet, meet, navigate]
    )

    const loadMeet = useCallback(async () => {
        try {
            await getMeet(id)

            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }, [getMeet, id])

    const formInitialValues = useMemo(() => {
        if (meet) {
            const { name, startAtDayJs, place } = meet.info
            return {
                title: name,
                date: startAtDayJs,
                place: place,
            }
        }
    }, [meet])

    useEffect(() => {
        if (id && meet?.info.id !== id) {
            loadMeet()
        } else {
            setIsLoading(false)
        }
        // eslint-disable-next-line
    }, [])

    if (isLoading) {
        return (
            <div className={cn(styles.spinner)}>
                <Spin size="large" />
            </div>
        )
    }

    return (
        <div className={cn(styles.root)}>
            <Wrapper>
                <header className={cn(styles.header)}>
                    <div>
                        <span className={cn(styles.title)}>
                            {meet
                                ? 'Редактирование встречи'
                                : 'Создание встречи'}
                        </span>
                    </div>

                    <Button
                        className={cn(styles.button)}
                        type="dashed"
                        htmlType="submit"
                        onClick={handleCloseClick}
                    >
                        Назад
                    </Button>
                </header>

                <Form onSave={handleSaveClick} values={formInitialValues} />
            </Wrapper>
        </div>
    )
}

export default observer(NewMeetingPage)
