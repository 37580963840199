import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons'

import styles from './index.module.scss'

const ICON = {
    plus: <CheckCircleOutlined className={cn(styles.plus)} />,
    minus: <CloseCircleOutlined className={cn(styles.minus)} />,
    question: <QuestionCircleOutlined className={cn(styles.question)} />,
}

const Status = ({ status }) => {
    return ICON[status] || ICON.question
}

export default observer(Status)
