import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { Button, Spin } from 'antd'

import { store } from '../../../stores/RootStore'

import { getNewMeetLink } from '../../../lib/links/getNewMeetLink'

import Wrapper from '../../Wrapper'
import Meets from '../../Meets'

import styles from './index.module.scss'

const MeetingsPage = () => {
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

    const { meets, getMeets } = store

    const loadMeets = useCallback(async () => {
        try {
            await getMeets()
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }, [getMeets])

    const handleNewMeetClick = useCallback(() => {
        navigate(getNewMeetLink())
    }, [navigate])

    useEffect(() => {
        loadMeets()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={cn(styles.root)}>
            <Wrapper>
                <header className={cn(styles.header)}>
                    <div>Встречи</div>

                    <Button
                        type="primary"
                        htmlType="button"
                        onClick={handleNewMeetClick}
                    >
                        Запланировать встречу
                    </Button>
                </header>

                {isLoading ? (
                    <div className={cn(styles.spinner)}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <Meets meets={meets} />
                )}
            </Wrapper>
        </div>
    )
}

export default observer(MeetingsPage)
