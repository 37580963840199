import axios from 'axios'

export class MeetsService {
    static async getMeets(token) {
        return await axios.get('https://api.mirrleon.cfd/v1/meets', {
            headers: {
                'X-Auth': token,
            },
        })

        // return {
        //     data: {
        //         meets: [
        //             {
        //                 info: {
        //                     id: 'fe411180-1c88-44f0-a565-6582b75dd2d3',
        //                     name: 'Новая встреча',
        //                     place: 'На бали',
        //                     start_at: '2023-08-15T13:11:03.000+03:00',
        //                     created_at: '2023-07-31T06:26:27.000+03:00',
        //                     org_web_app_user: {
        //                         id: 352348356,
        //                         first_name: 'Владимир',
        //                         last_name: 'Леонов',
        //                         username: 'mirrleon',
        //                     },
        //                 },
        //                 myInfo: {
        //                     participantStatus: 'plus',
        //                 },
        //             },
        //             {
        //                 info: {
        //                     id: '11a9c7c7-646d-45f7-a6b5-78c02fa869f6',
        //                     name: 'йцукцук',
        //                     place: 'Место',
        //                     start_at: '2023-08-03T01:58:38.000+03:00',
        //                     created_at: '2023-08-01T01:58:47.000+03:00',
        //                     org_web_app_user: {
        //                         id: 352348356,
        //                         first_name: 'Владимир',
        //                         last_name: 'Леонов',
        //                         username: 'mirrleon',
        //                     },
        //                 },
        //                 myInfo: {
        //                     participantStatus: 'minus',
        //                 },
        //             },
        //             {
        //                 info: {
        //                     id: 'ba2297b2-828d-4b97-8fe7-05abc5900095',
        //                     name: 'Test',
        //                     place: 'test place',
        //                     start_at: '2023-01-01T05:00:00.000+03:00',
        //                     created_at: '2023-07-31T22:53:18.000+03:00',
        //                     org_web_app_user: {
        //                         id: 352348356,
        //                         first_name: 'Владимир',
        //                         last_name: 'Леонов',
        //                         username: 'mirrleon',
        //                     },
        //                 },
        //                 myInfo: {
        //                     participantStatus: null,
        //                 },
        //             },
        //         ],
        //     },
        // }
    }

    static async getMeet(token, id) {
        return await axios.get(`https://api.mirrleon.cfd/v1/meets/${id}`, {
            headers: {
                'X-Auth': token,
            },
        })

        // return {
        //     data: {
        //         info: {
        //             id: 'fe411180-1c88-44f0-a565-6582b75dd2d3',
        //             name: 'Новая встреча',
        //             place: 'На бали',
        //             start_at: '2023-08-15T13:11:03.000+03:00',
        //             created_at: '2023-07-31T06:26:27.000+03:00',
        //             org_web_app_user: {
        //                 id: 352348356,
        //                 first_name: 'Владимир',
        //                 last_name: 'Леонов',
        //                 username: 'mirrleon',
        //             },
        //         },
        //         participantGroups: [
        //             {
        //                 participants: [
        //                     {
        //                         id: 3412334,
        //                         first_name: 'Ivan',
        //                         last_name: 'Ivanov',
        //                         username: 'iva800',
        //                     },
        //                     {
        //                         id: 3412334,
        //                         first_name: 'Lola',
        //                         last_name: 'Lolalalal',
        //                         username: 'lalala',
        //                     },
        //                 ],
        //                 status: 'plus',
        //             },
        //             {
        //                 participants: [
        //                     {
        //                         id: 3412334,
        //                         first_name: 'Tolya',
        //                         last_name: 'Tolya',
        //                         username: 'toll',
        //                     },
        //                     {
        //                         id: 3412334,
        //                         first_name: 'Mary',
        //                         last_name: 'Marylala',
        //                         username: 'maryyyy',
        //                     },
        //                 ],
        //                 status: 'minus',
        //             },
        //         ],
        //         myInfo: {
        //             participantStatus: null,
        //         },
        //     },
        // }
    }

    static async createMeet(token, data) {
        return await axios.post(`https://api.mirrleon.cfd/v1/meets`, data, {
            headers: {
                'X-Auth': token,
            },
        })
    }

    static async updateMeet(token, id, data) {
        return await axios.post(
            `https://api.mirrleon.cfd/v1/meets/${id}`,
            data,
            {
                headers: {
                    'X-Auth': token,
                },
            }
        )
    }

    static async changeMeetStatus(token, id, status) {
        return await axios.post(
            `https://api.mirrleon.cfd/v1/meets/${id}/participant`,
            { status },
            {
                headers: {
                    'X-Auth': token,
                },
            }
        )
    }
}
