import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import MeetsMeet from './MeetsMeet'

import styles from './index.module.scss'

const Meets = ({ meets }) => {
    return (
        <ul className={cn(styles.root)}>
            {meets.map((meet) => (
                <MeetsMeet key={meet.id} meet={meet} />
            ))}
        </ul>
    )
}

export default observer(Meets)
