import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import styles from './index.module.scss'

const ListOfParticipantsGroup = ({ list, title }) => {
    if (list.length === 0) {
        return null
    }

    return (
        <div className={cn(styles.root)}>
            <div className={cn(styles.title)}>
                {title} ({list.length})
            </div>

            <ul className={cn(styles.list)}>
                {list.map(({ firstName, lastName, userName }, i) => (
                    <li key={i}>
                        <div>
                            <span className={cn(styles.firstName)}>
                                {firstName}
                            </span>
                            <span className={cn(styles.lastName)}>
                                {lastName}
                            </span>
                            <span className={cn(styles.userName)}>
                                {userName}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default observer(ListOfParticipantsGroup)
